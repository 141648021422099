import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from '../constants';
import {BehaviorSubject, Observable, Subject} from 'rxjs';


const Headers = new HttpHeaders({
  'Content-Type': 'application/json',
  'x-api-key': '5f7af37cb35f5cd8',
  Authorization: 'Bearer ' + window.localStorage.getItem('token')
});
@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private http: HttpClient) {}

  // for Header & Sidebar
  public userLoggedIn = true;

  signUp(user_name, password) {
    return this.http.post(Constants.apiAddress + 'api/v2/auth/web_login/', JSON.stringify({user_name, password}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8'}) });
  }

  getDashboardStatistics() {
    return this.http.get(Constants.apiAddress + 'api/v1/utilities/dashboard/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getDashboardCharts() {
    return this.http.get(Constants.apiAddress + 'api/v1/utilities/dashboard_charts/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  // for restaurant listing
  getRestaurantNewRequestStatistics() {
    return this.http.get(Constants.apiAddress + 'api/v2/restaurants_count',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getRestaurantNewRequest(status) {
    return this.http.get(Constants.apiAddress + 'api/v2/restaurants/?status=' + status,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getAllRestaurantRequest() {
    return this.http.get(Constants.apiAddress + 'api/v2/restaurants/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateFeaturedRequest(id) {
    return this.http.get(Constants.apiAddress + 'api/v2/restaurants/' + id + '/feature_restaurant/',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  // for restaurant detail
  getCountryList() {
    return this.http.get(Constants.apiAddress + 'api/v1/utilities/countries/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getRole() {
    return this.http.get(Constants.apiAddress + 'api/v1/auth/get_user_types/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  createRestaurant(createData) {
    return this.http.post(Constants.apiAddress + 'api/v2/auth/restaurant_registration/', JSON.stringify(createData),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getRestaurantDetail(id) {
    return this.http.get(Constants.apiAddress + 'api/v2/restaurants/' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateNewRestaurantRequest(createData, id) {
    return this.http.put(Constants.apiAddress + 'api/v2/restaurants/' + id + '/', JSON.stringify(createData),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateRestaurantStatusFun(restaurant, restaurantData) {
    return this.http.put(Constants.apiAddress + 'api/v2/restaurants/' + restaurantData.id + '/', JSON.stringify({restaurant}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  // restaurant menu start
  getMenuCategoryFun() {
    return this.http.get(Constants.apiAddress + 'api/v2/menu_types/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  addMenuCategoryFun(menu) {
    return this.http.post(Constants.apiAddress + 'api/v2/menu_types/', JSON.stringify(menu),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateMenuCategoryFun(menu, id) {
    return this.http.put(Constants.apiAddress + 'api/v2/menu_types/' + id + '/', JSON.stringify(menu),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deleteMenuCategoryFun(id) {
    return this.http.delete(Constants.apiAddress + 'api/v2/menu_types/' + id + '/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  addMenuItemFun(menuItem) {
    return this.http.post(Constants.apiAddress + 'api/v2/menu_items/', JSON.stringify(menuItem),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateMenuItemFun(menu) {
    return this.http.put(Constants.apiAddress + 'api/v2/menu_items/' + menu.menu_type_id + '/', JSON.stringify(menu),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deleteMenuItemFun(id) {
    return this.http.delete(Constants.apiAddress + 'api/v2/menu_items/' + id + '/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }


  getMenuCategoryItemFun(id) {
    return this.http.get(Constants.apiAddress + 'api/v2/menu_items/' + id,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  addMenuItemVariantFun(menuItem) {
    return this.http.post(Constants.apiAddress + 'api/v3/varient/', JSON.stringify(menuItem),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  editMenuItemVariantFun(menu, obj) {
    return this.http.put(Constants.apiAddress + 'api/v3/varient/' + obj.id + '/', JSON.stringify(menu),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deleteVariantFun(item) {
    return this.http.delete(Constants.apiAddress + 'api/v3/varient/' + item.id + '/',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }


  getMenuAddOnsFun(id) {
    return this.http.get(Constants.apiAddress + 'api/v2/menu_types/' + id + '/menu_type_add_on/',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  addMenuAddOnsFun(addOns) {
    return this.http.post(Constants.apiAddress + 'api/v3/add_on/', JSON.stringify(addOns),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  editMenuAddOnsFun(menu, obj) {
    return this.http.put(Constants.apiAddress + 'api/v3/add_on/' + obj.id + '/', JSON.stringify(menu),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deleteAddOnsFun(item) {
    return this.http.delete(Constants.apiAddress + 'api/v3/add_on/' + item.id + '/',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  // get Restaurant Profile
  getCuisineListFun() {
    return this.http.get(Constants.apiAddress + 'api/v2/cuisine_type/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getFoodTypeListFun() {
    return this.http.get(Constants.apiAddress + 'api/v2/food_type/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getMealTypeListFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/utilities/meal_types/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getExclusionTypeListFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/utilities/exclusions/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateCuisineFun(cuisine_types, id) {
    return this.http.put(Constants.apiAddress + 'api/v2/restaurant_cuisine_type/' + id + '/',  JSON.stringify({cuisine_types}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateFoodTypeFun(food_types, id) {
    return this.http.put(Constants.apiAddress + 'api/v2/restaurant_food_type/' + id + '/',  JSON.stringify({food_types}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getProfileInfoFun(id) {
    return this.http.get(Constants.apiAddress + 'api/v2/restaurant_info/' + id + '/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  // restaurant Profile
  updateRestaurantTiming(business_hours, restaurant_id) {
    return this.http.post(Constants.apiAddress + 'api/v1/utilities/business_hour/', JSON.stringify({business_hours, restaurant_id}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  // restaurant Deals
  getActiveDealsFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/deals/active/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getDealsStatsFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/deals/statistics/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getPreviousDealsFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/deals/previous/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getDealDetailFun(id) {
    return this.http.get(Constants.apiAddress + 'api/v1/deals/deal_detail/?id=' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  addRestaurantDeals(deals) {
    return this.http.post(Constants.apiAddress + 'api/v1/deals/', JSON.stringify(deals),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deleteActiveDealFun(id, status) {
    return this.http.put(Constants.apiAddress + 'api/v1/deals/' + id + '/', JSON.stringify({status}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateRestaurantDeals(id, deals) {
    return this.http.put(Constants.apiAddress + 'api/v1/deals/' + id + '/', JSON.stringify(deals),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getRestaurantImageFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/utilities/upload_restaurant_media/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deleteRestaurantImageFun(id) {
    return this.http.post(Constants.apiAddress + 'api/v1/utilities/upload_restaurant_media/', JSON.stringify({id}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  addRestaurantImageFun(gallery_images) {
    return this.http.post(Constants.apiAddress + 'api/v1/utilities/upload_restaurant_media/', JSON.stringify({gallery_images}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  addRestaurantCoverImageFun(cover_image) {
    return this.http.post(Constants.apiAddress + 'api/v1/utilities/upload_restaurant_media/', JSON.stringify({cover_image}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }


  // restaurant membership
  getRestaurantMembershipFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/utilities/membership/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }


  getMenuTermFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/menu_terms/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  addMenuTermFun(term1) {
    return this.http.post(Constants.apiAddress + 'api/v1/menu_terms/', JSON.stringify({term1}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateMenuTermFun(term1, id) {
    return this.http.put(Constants.apiAddress + 'api/v1/menu_terms/' + id + '/', JSON.stringify({term1}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  getPageFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/auth/permissions/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deletePageFun(id, remove) {
    return this.http.put(Constants.apiAddress + 'api/v1/auth/permissions/', JSON.stringify({id, remove}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updatePageSortFun(permissions) {
    return this.http.post(Constants.apiAddress + 'api/v1/auth/permissions_order/', JSON.stringify({permissions}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  addPageFun(page) {
    return this.http.post(Constants.apiAddress + 'api/v1/auth/permissions/', JSON.stringify(page),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  editPageFun(name, url, icon, code, type, id) {
    return this.http.put(Constants.apiAddress + 'api/v1/auth/permissions/', JSON.stringify({name, url, icon, code, type, id}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  getRoleFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/auth/role/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deleteRoleFun(id, remove) {
    return this.http.put(Constants.apiAddress + 'api/v1/auth/role/', JSON.stringify({id, remove}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getMobileRoleFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/auth/get_app_user_types/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  addRoleFun(name, type) {
    return this.http.post(Constants.apiAddress + 'api/v1/auth/role/', JSON.stringify({name, type}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  editRoleFun(name, type, id) {
    return this.http.put(Constants.apiAddress + 'api/v1/auth/role/', JSON.stringify({name, type, id}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  updatePermissionsFun(permission, role) {
    return this.http.put(Constants.apiAddress + 'api/v1/auth/role_permission/',  JSON.stringify({permission, role}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  addAccountFun(accounts) {
    return this.http.post(Constants.apiAddress + 'api/v1/auth/user_role/', JSON.stringify(accounts),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  editAccountFun(email, phone, name, password, user_name, type, app_user_type, id) {
    return this.http.put(Constants.apiAddress + 'api/v1/auth/user_role/', JSON.stringify({email, phone, name, password, user_name, type, app_user_type, id}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getAccountFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/auth/user_role/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deleteRestaurantAccountFun(id, remove) {
    return this.http.put(Constants.apiAddress + 'api/v1/auth/user_role/', JSON.stringify({id, remove}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  addCompanyAccountFun(accounts) {
    return this.http.post(Constants.apiAddress + 'api/v1/auth/company_user/', JSON.stringify(accounts),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  editCompanyAccountFun(email, phone, name, password, user_name, type, id) {
    return this.http.put(Constants.apiAddress + 'api/v1/auth/company_user/', JSON.stringify({email, phone, name, password, user_name, type, id}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getCompanyAccountFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/auth/company_user/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deleteAccountFun(id, remove) {
    return this.http.put(Constants.apiAddress + 'api/v1/auth/company_user/', JSON.stringify({id, remove}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }



  // database Api
  getRestaurantDataFun(id) {
    return this.http.get(Constants.apiAddress + 'api/v2/database_view/' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getRestaurantFeedbackDataFun(id) {
    return this.http.get(Constants.apiAddress + 'api/v1/customer_feed_back/?reservation_id=' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  // restaurant Logs restaurant_logs
  getRestaurantLogsFun(id: string) {
    return this.http.get(Constants.apiAddress + 'logs/restaurant_logs?restaurant_id=' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  // customers
  getRestaurantCustomerFun(type) {
    return this.http.get(Constants.apiAddress + 'api/v1/deals/customers/?filter_value=' + type,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getSystemCustomerSummaryFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/twidlee_customers/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getSystemCustomerListFun(type) {
    return this.http.get(Constants.apiAddress + 'api/v1/customers_list/?filter_value=' + type,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getAllSystemCustomerListFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/customers/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getSystemWorstCustomerListFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/worst_customers_list/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getCustomerChartFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/twidlee_customers_charts/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  // raiting and summary system admin
  getsystemOverallRatingFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/stats/admin_rating_stats/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getSystemAdminDealStatsFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/stats/admin_deal_stats/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  // raiting and summary business admin
  getDealSummaryFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/deals/ratings_summary/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getDealRatingFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/deals/deals_stats/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }


  // duplicate Restaurant data
  duplicateMenuFun(from_restaurant_id, to_restaurant_id) {
    return this.http.post(Constants.apiAddress + 'api/v1/utilities/duplicate_menu/', JSON.stringify({from_restaurant_id, to_restaurant_id}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }


  // admin configurator start
  // discount type crud start
  getOtherDealsListFun() {
    return this.http.get(Constants.apiAddress + 'api/v2/discount_type/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  addOtherDealsListFun(title) {
    return this.http.post(Constants.apiAddress + 'api/v2/discount_type/', JSON.stringify({title}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateOtherDealsListFun(title, id) {
    return this.http.put(Constants.apiAddress + 'api/v2/discount_type/' + id + '/', JSON.stringify({title}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deleteOtherDealsListFun(id) {
    return this.http.delete(Constants.apiAddress + 'api/v2/discount_type/' + id + '/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  // meal type crud start
  getMealTypFun() {
    return this.http.get(Constants.apiAddress + 'api/v2/meal_type/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  addMealTypeListFun(name) {
    return this.http.post(Constants.apiAddress + 'api/v2/meal_type/', JSON.stringify({name}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateMealTypeListFun(name, id) {
    return this.http.put(Constants.apiAddress + 'api/v2/meal_type/' + id + '/', JSON.stringify({name}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deleteMealTypeListFun(id) {
    return this.http.delete(Constants.apiAddress + 'api/v2/meal_type/' + id + '/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }


  // Exclusion type crud start
  getExclusionTypeFun() {
    return this.http.get(Constants.apiAddress + 'api/v2/exclusion/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  addExclusionTypeListFun(name) {
    return this.http.post(Constants.apiAddress + 'api/v2/exclusion/', JSON.stringify({name}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateExclusionTypeListFun(name, id) {
    return this.http.put(Constants.apiAddress + 'api/v2/exclusion/' + id + '/', JSON.stringify({name}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deleteExclusionTypeListFun(id) {
    return this.http.delete(Constants.apiAddress + 'api/v2/exclusion/' + id + '/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }


  // get application stats

  getApplicationStats() {
    return this.http.get(Constants.apiAddress + 'api/v2/auth/application_downloads/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  updatetApplicationStats(totalDownloads) {
    return this.http.post( Constants.apiAddress + 'api/v2/auth/application_downloads/', JSON.stringify(totalDownloads as number),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  getReportsFun(model_type, status, start_time, end_time) {
    return this.http.get(Constants.apiAddress + 'api/v2/auth/report/?model_type=' + model_type + '&status=' + status + '&start_time=' + start_time + '&end_time=' + end_time,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getOrdersReportsFun(model_type, status, start_time, end_time, id) {
    return this.http.get(Constants.apiAddress + 'api/v3/order_report/?model_type=' + model_type + '&status=' + status + '&start_time=' + start_time + '&end_time=' + end_time + '&id=' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }


  // delivery Request
  getDeliveryRequest() {
    return this.http.get(Constants.apiAddress + 'api/v3/delivery_request/get_delivery_restaurants_request/',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getDeliveryRequestById(id) {
    return this.http.get(Constants.apiAddress + 'api/v3/delivery_request/' + id + '/',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  addDeliveryRequest(deliveryRequest) {
    return this.http.post( Constants.apiAddress + 'api/v3/delivery_request/', JSON.stringify(deliveryRequest),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateDeliveryRequest(deliveryRequest, id) {
    return this.http.put( Constants.apiAddress + 'api/v3/delivery_request/' + id.id + '/', JSON.stringify(deliveryRequest),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  getRestaurantNewDeliveryRequest() {
    return this.http.get(Constants.apiAddress + 'api/v3/delivery_request/',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateRestaurantDeliveryStatusFun(restaurant, restaurantData) {
    return this.http.put(Constants.apiAddress + 'api/v3/delivery_request/' + restaurantData.id + '/', JSON.stringify(restaurant),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }


  // rider crud start
  getAllRiderListByStatusFun(status) {
    return this.http.get(Constants.apiAddress + 'api/v3/riders/rider/?status=' + status,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getAllRiderListFun() {
    return this.http.get(Constants.apiAddress + 'api/v3/riders/rider/',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getRiderDetailsById(id) {
    return this.http.get(Constants.apiAddress + 'api/v3/riders/rider/' + id + '/',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  addRiderRequest(deliveryRequest) {
    return this.http.post( Constants.apiAddress + 'api/v3/register_rider/', JSON.stringify(deliveryRequest),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateRiderRequest(deliveryRequest, id) {
    return this.http.put( Constants.apiAddress + 'api/v3/riders/rider/' + id + '/', JSON.stringify(deliveryRequest),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateRiderStatusFun(request, restaurantData) {
    return this.http.put(Constants.apiAddress + 'api/v3/riders/rider/' + restaurantData.id + '/', JSON.stringify(request),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }


  getBlueAreaFun() {
    return this.http.get(Constants.apiAddress + 'api/v3/riders/blue_area',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  getSlotsFun() {
    return this.http.get(Constants.apiAddress + 'api/v3/riders/slots/',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  addSlotsRequest(request) {
    return this.http.post( Constants.apiAddress + 'api/v3/riders/slots/', JSON.stringify(request),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  UpdateSlotsRequest(request, id) {
    return this.http.put( Constants.apiAddress + 'api/v3/riders/slots/' + id + '/', JSON.stringify(request),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deleteSlotFun(id) {
    return this.http.delete(Constants.apiAddress + 'api/v3/riders/slots/' + id,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }


  getAppVersionFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/version/',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  addAppVersionFun(request) {
    return this.http.post( Constants.apiAddress + 'api/v1/version/', JSON.stringify(request),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateAppVersionFun(request, id) {
    return this.http.put( Constants.apiAddress + 'api/v1/version/' + id + '/', JSON.stringify(request),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deleteAppVersionFun(id) {
    return this.http.delete(Constants.apiAddress + 'api/v1/version/' + id,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }


  getAppNotificationFun() {
    return this.http.get(Constants.apiAddress + 'api/v1/notification/',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  addAppNotificationFun(request) {
    return this.http.post( Constants.apiAddress + 'api/v1/notification/', JSON.stringify(request),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  updateAppNotificationFun(request, id) {
    return this.http.put( Constants.apiAddress + 'api/v1/notification/' + id + '/', JSON.stringify(request),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deleteAppNotificationFun(id) {
    return this.http.delete(Constants.apiAddress + 'api/v1/notification/' + id,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }


  addBlueAreaRequest(request) {
    return this.http.post( Constants.apiAddress + 'api/v3/riders/blue_area/', JSON.stringify(request),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  UpdateBlueAreaRequest(request, id) {
    return this.http.put( Constants.apiAddress + 'api/v3/riders/blue_area/' + id + '/', JSON.stringify(request),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deleteBlueAreaFun(id) {
    return this.http.delete(Constants.apiAddress + 'api/v3/riders/blue_area/' + id,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }





  getDeliveryDeals() {
    return this.http.get(Constants.apiAddress + 'api/v3/delivery_deals/',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  addDeliveryDeals(request) {
    return this.http.post(Constants.apiAddress + 'api/v3/delivery_deals/', JSON.stringify(request),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deleteDeliveryDeals(id) {
    return this.http.delete(Constants.apiAddress + 'api/v3/delivery_deals/' + id,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateDeliveryDealsStatus(id) {
    return this.http.get(Constants.apiAddress + 'api/v3/delivery_deals/delivery_deal_status?id=' + id,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  updateDeliveryDeals(id, name, price, description) {
    return this.http.put(Constants.apiAddress + 'api/v3/delivery_deals/' + id + '/', JSON.stringify({name, price, description}),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }


  getDeliveryDealsOrders(status) {
    return this.http.get(Constants.apiAddress + 'api/v3/order/active_orders',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  getDeliveryDealsOrdersHistory(filter, id) {
    return this.http.get(Constants.apiAddress + 'api/v3/order?filter_value=' + filter + '&restaurant_id=' + id,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  updateDeliveryDealsOrders(id, status) {
    return this.http.put(Constants.apiAddress + 'api/v3/order/' + id + '/', JSON.stringify({status}),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }


  getRiderNotificationFun() {
    return this.http.get(Constants.apiAddress + 'api/v3/riders/notification/',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  addRiderNotificationFun(request) {
    return this.http.post( Constants.apiAddress + 'api/v3/riders/notification/', JSON.stringify(request),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  updateRiderNotificationFun(request, id) {
    return this.http.put( Constants.apiAddress + 'api/v3/riders/notification/' + id + '/', JSON.stringify(request),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  deleteRiderNotificationFun(id) {
    return this.http.delete(Constants.apiAddress + 'api/v3/riders/notification/' + id,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  updateRiderNotificationStatus(id) {
    return this.http.get(Constants.apiAddress + 'api/v3/riders/notification/change_notification_status?id=' + id,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }







  getOrderLedgerList(id) {
    return this.http.get(Constants.apiAddress + 'api/v3/riders/order_ladger?rider_id=' + id,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getRiderLedgerList(id) {
    return this.http.get(Constants.apiAddress + 'api/v3/riders/rider_ladger?rider_id=' + id,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getPaymentFromRiderList(id) {
    return this.http.get(Constants.apiAddress + 'api/v3/riders/payment_from_rider?rider_id=' + id,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getPaymentToRiderList(id) {
    return this.http.get(Constants.apiAddress + 'api/v3/riders/payment_send_rider?rider_id=' + id,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  paymentReceivedFromLedgerFun(amount, date, rider) {
    return this.http.post( Constants.apiAddress + 'api/v3/riders/payment_from_rider/', JSON.stringify({amount, date, rider}),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  paymentSendToRiderFun(amount, date, rider) {
    return this.http.post( Constants.apiAddress + 'api/v3/riders/payment_send_rider/', JSON.stringify({amount, date, rider}),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }




  // restaurant ledger
  getRestaurantLedgerList(id) {
    return this.http.get(Constants.apiAddress + 'api/v3/restaurant_ladger?restaurant_id=' + id,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getRestaurantPaymentLedgerList(id) {
    return this.http.get(Constants.apiAddress + 'api/v3/restaurant_payment?restaurant_id=' + id,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  paymentSendToRestaurantFun(amount, date, restaurant_id) {
    return this.http.post( Constants.apiAddress + 'api/v3/restaurant_payment/', JSON.stringify({amount, date, restaurant_id}),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }



  getTwidleeLedgerList() {
    return this.http.get(Constants.apiAddress + 'api/v3/twidlee_ladger',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getOrderLedgerListFun() {
    return this.http.get(Constants.apiAddress + 'api/v3/order_ladger',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getPaymentFromLedgerFun() {
    return this.http.get(Constants.apiAddress + 'api/v3/riders/payment_from_rider',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getPaymentToRiderFun() {
    return this.http.get(Constants.apiAddress + 'api/v3/riders/payment_send_rider',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  getRestaurantPaymentsFun() {
    return this.http.get(Constants.apiAddress + 'api/v3/restaurant_payment',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }


  getRestaurantlistNames() {
    return this.http.get(Constants.apiAddress + 'api/v3/restaurant_detail/',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }

  getUserlistNames() {
    return this.http.get(Constants.apiAddress + 'api/v3/user_detail/',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getRiderlistNames() {
    return this.http.get(Constants.apiAddress + 'api/v3/rider_detail/',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }


  getCustomerDetailsFun(id) {
    return this.http.get(Constants.apiAddress + 'api/v1/customers_detail/?id=' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
  getCustomerChartDetailsFun(id) {
    return this.http.get(Constants.apiAddress + 'api/v1/customers_charts/?id=' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', Authorization: 'Bearer ' + window.localStorage.getItem('token') }) });
  }
}





